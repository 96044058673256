<template>
  <div class="flex mx-auto md:ml-auto py-8 sm:py-0 md:py.0 lg:py-0">
    <router-link to="/requestaccess" class="self-start bg-dcgreen text-white text-lg font-bold uppercase px-10 py-2 tracking-wider">Request access</router-link>
  </div>
</template>

<script>
    export default {
        name: "RequestAccessButton"
    }
</script>

<style scoped>

</style>
