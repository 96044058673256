<template>
  <div class="access bg-dcgray pb-12 md:pb-64 page-content">
    <div class="header-img">
      <h1 class="self-center text-white text-26875 font-bold">Pricing and Plans</h1>
    </div>

    <div class="mx-auto md:content-max-width mb-8">
      <div class="flex justify-between flex-col sm:flex-row md:flex-row mx-16 mb-16">
        <div class="access-select flex md:hidden w-full pr-4">
            <select class="w-full p-6" @change="(e) => handleChange(e.target.value)" name="">
                <option value="/access">Packages</option>
                <option value="/access/account-types">Account type</option>
                <option value="/access/additional-material">Additional material</option>
            </select>
        </div>
        <div class="hidden md:flex">

          <router-link
            to="/access"
            class="relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 mr-4 tracking-widest"
            :active="isActive"
            :class="[
              addPackagesArrow ? 'packages-arrow' : '',
              addPackagesArrow ? 'bg-dcblue': '',
              addPackagesArrow ? 'border-dcblue': '',
              addPackagesArrow ? 'text-white': '',
              ]">
            Packages
          </router-link>

          <router-link
            to="/access/account-types"
            class="relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 mr-4 tracking-widest"
            :active="isActive"
            :class="[
              addAccountTypeArrow ? 'account-type-arrow' : '',
              addAccountTypeArrow ? 'bg-dcblue': '',
              addAccountTypeArrow ? 'border-dcblue': '',
              addAccountTypeArrow ? 'text-white': '',
              ]">
            Account types
          </router-link>

          <router-link
            to="/access/additional-material"
            class="relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 tracking-widest"
            :active="isActive"
            :class="[
              addAdditionalMaterialArrow ? 'additional-material-arrow' : '',
              addAdditionalMaterialArrow ? 'bg-dcblue': '',
              addAdditionalMaterialArrow ? 'border-dcblue': '',
              addAdditionalMaterialArrow ? 'text-white': '',
              ]">
            Additional material
          </router-link>

        </div>
        <RequestAccessButton></RequestAccessButton>
      </div>

      <router-view/>
    </div>
  </div>
</template>

<script>
    import RequestAccessButton from "../components/RequestAccessButton";

    export default {
        name: "Access",
        components: {RequestAccessButton},
        data: function () {
            return {
                isActive: '',
            }
        },

      computed: {
        addPackagesArrow: function () {
          return this.$route.name === 'access';
        },

        addAccountTypeArrow: function () {
          return this.$route.name === 'account types';
        },

        addAdditionalMaterialArrow: function () {
          return this.$route.name === 'additional material';
        }
      },
      methods: {
          handleChange(value) {
              this.$router.push(value)
          }
      }

    }
</script>

<style scoped>
  .packages-arrow:after {
    content: " ";
    position: absolute;
    right: 55px;
    bottom: -12px;
    border-top: 12px solid black;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: none;
  }

  .account-type-arrow:after {
    content: " ";
    position: absolute;
    right: 75px;
    bottom: -12px;
    border-top: 12px solid black;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: none;
  }

  .additional-material-arrow:after {
    content: " ";
    position: absolute;
    right: 112px;
    bottom: -12px;
    border-top: 12px solid black;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: none;
  }

  .access-select {
      position: relative;
  }

  .access-select select {
      border: 1px solid #081b33
  }

  .access-select:after {
      position: absolute;
      content: '';
      border-top: 12px solid black;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: none;
      top: 45%;
      right: 25px;
  }

</style>
