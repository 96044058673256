var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"access bg-dcgray pb-12 md:pb-64 page-content"},[_vm._m(0),_c('div',{staticClass:"mx-auto md:content-max-width mb-8"},[_c('div',{staticClass:"flex justify-between flex-col sm:flex-row md:flex-row mx-16 mb-16"},[_c('div',{staticClass:"access-select flex md:hidden w-full pr-4"},[_c('select',{staticClass:"w-full p-6",attrs:{"name":""},on:{"change":function (e) { return _vm.handleChange(e.target.value); }}},[_c('option',{attrs:{"value":"/access"}},[_vm._v("Packages")]),_c('option',{attrs:{"value":"/access/account-types"}},[_vm._v("Account type")]),_c('option',{attrs:{"value":"/access/additional-material"}},[_vm._v("Additional material")])])]),_c('div',{staticClass:"hidden md:flex"},[_c('router-link',{staticClass:"relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 mr-4 tracking-widest",class:[
            _vm.addPackagesArrow ? 'packages-arrow' : '',
            _vm.addPackagesArrow ? 'bg-dcblue': '',
            _vm.addPackagesArrow ? 'border-dcblue': '',
            _vm.addPackagesArrow ? 'text-white': '' ],attrs:{"to":"/access","active":_vm.isActive}},[_vm._v(" Packages ")]),_c('router-link',{staticClass:"relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 mr-4 tracking-widest",class:[
            _vm.addAccountTypeArrow ? 'account-type-arrow' : '',
            _vm.addAccountTypeArrow ? 'bg-dcblue': '',
            _vm.addAccountTypeArrow ? 'border-dcblue': '',
            _vm.addAccountTypeArrow ? 'text-white': '' ],attrs:{"to":"/access/account-types","active":_vm.isActive}},[_vm._v(" Account types ")]),_c('router-link',{staticClass:"relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 tracking-widest",class:[
            _vm.addAdditionalMaterialArrow ? 'additional-material-arrow' : '',
            _vm.addAdditionalMaterialArrow ? 'bg-dcblue': '',
            _vm.addAdditionalMaterialArrow ? 'border-dcblue': '',
            _vm.addAdditionalMaterialArrow ? 'text-white': '' ],attrs:{"to":"/access/additional-material","active":_vm.isActive}},[_vm._v(" Additional material ")])],1),_c('RequestAccessButton')],1),_c('router-view')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-img"},[_c('h1',{staticClass:"self-center text-white text-26875 font-bold"},[_vm._v("Pricing and Plans")])])}]

export { render, staticRenderFns }