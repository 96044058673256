<template>
  <div class="learn bg-dcgray pb-12 md:pb-64 page-content">
    <div class="header-img">
      <h1 class="self-center text-white text-26875 font-bold">Learn</h1>
    </div>

    <div class="mx-auto md:content-max-width mb-8">
      <div class="flex justify-between flex-col sm:flex-row md:flex-row mx-16 mb-8">
        <div class="access-select flex md:hidden w-full pr-4">
            <select class="w-full p-6" @change="(e) => handleChange(e.target.value)" name="">
                <option value="/learn">Tutorials</option>
                <option value="/access/account-types">Account type</option>
                <option value="/access/additional-material">Additional material</option>
            </select>
        </div>
        <div class="hidden md:flex tab-buttons">

          <router-link
            to="/learn/tutorials"
            class="relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 mr-4 tracking-widest">
            Tutorials
          </router-link>

          <router-link
            to="/learn/knowledge"
            class="relative cursor-pointer self-start uppercase font-semibold border-solid border border-dcbordergray rounded px-5 py-2 mr-4 tracking-widest">
            Knowledge
          </router-link>

        </div>
      </div>

      <router-view/>
    </div>
  </div>
</template>

<script>

    export default {
        name: "Access",
        data: function () {
            return {
                isActive: '',
            }
        },
        created() {
            this.$router.push('/learn/tutorials')
        },

      computed: {
        addPackagesArrow: function () {
          return this.$route.name === 'learn';
        },

        addAccountTypeArrow: function () {
          return this.$route.name === 'account types';
        },

        addAdditionalMaterialArrow: function () {
          return this.$route.name === 'additional material';
        }
      },
      methods: {
          handleChange(value) {
              this.$router.push(value)
          }
      }

    }
</script>

<style lang="scss">
    .learn {
        .tab-buttons {
            .router-link-active {
                background-color: #081B33;
                color: #FFF;
                position: relative;

                &:after {
                  content: " ";
                  position: absolute;
                  right: calc(50% - 6px);
                  bottom: -12px;
                  border-top: 12px solid #081B33;
                  border-right: 7px solid transparent;
                  border-left: 7px solid transparent;
                  border-bottom: none;
                }
            }
        }
    }

</style>
